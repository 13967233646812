import { HydratedRouter } from "react-router/dom";
import { startTransition, StrictMode, useEffect } from "react";
import {
  useLocation,
  createRoutesFromChildren,
  useNavigationType,
  matchRoutes,
} from "react-router";
import * as Sentry from "@sentry/react";
import { hydrateRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { getServerClient } from "./graphql";
if (webConfig.environment === "production") {
  Sentry.init({
    dsn: "https://27f9d83810da7100c57f985afc6a2ab8@o457803.ingest.us.sentry.io/4508953854869504",
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: webConfig.environment,
    release: webConfig.revision,
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // ...
  });
}

startTransition(() => {
  const client = getServerClient();
  hydrateRoot(
    document,
    <StrictMode>
      <ApolloProvider client={client}>
        <HydratedRouter />
      </ApolloProvider>
    </StrictMode>,
  );
});
